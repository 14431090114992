import React from "react";

const Footer = () => {

    return (
        <>
        <footer className="all_padding background_all">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="foot_log">
                            <a href="/"> <img src="images/lecno_logo.png" alt="" /></a>
                            <p>At Techno IT Solution, we are dedicated to providing innovative IT solutions tailored to meet the diverse
                                needs of businesses. Our team combines technical expertise with creativity to drive success.</p>
                            <div className="d-flex links_social">
                                <a href="https://www.instagram.com/p/C6xu1pZrqSF/?igsh=NHF1eXZjd2ZiNW4x" target="-blank"><i className="fa-brands fa-instagram"></i></a>
                                <a href="https://www.facebook.com/share/p/rJbbLXN6atP5zZk5/?mibextid=WC7FNe" target="-blank"><i className="fa-brands fa-facebook-f"></i></a>
                                <a href="https://api.whatsapp.com/send?phone=918947943806" target="-blank"><i className="fa-brands fa-whatsapp"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="implinks yvyt">
                            <h2>Quick LINKS</h2>
                            <ul className="linkss">
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/service">Services</a></li>
                                <li><a href="/portfolio">Portfolio</a></li>
                                <li><a href="/gallery">Gallery</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="implinks">
                            <h2>Our Services</h2>
                            <ul className="linkss">
                                <li><a >Web Development</a></li>
                                <li><a >App Development</a></li>
                                <li><a >Web Designing</a></li>
                                <li><a >SEO Services</a></li>
                                <li><a >Graphic Designing</a></li>
                                <li><a >Content Marketing</a></li>
                                <li><a >Bulk & Voice SMS</a></li>
                                <li><a >Lead Generation</a></li>
                                <li><a >GMB Services</a></li>
                                <li><a >SMO Services</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="implinks dsa">
                            <h2>Get In Touch</h2>
                            <ul className="linkss">
                                <li><a href="tel:+91 8947943806"> <i className="fa-solid fa-phone"></i> +91 8947943806</a></li>
                                <li><a href="mailto:technoitsolution63@gmail.com"><i className="fa-solid fa-envelope"></i>
                                    technoitsolution63<br />@gmail.com</a></li>
                                <li><a href=""><i className="fa-solid fa-location-dot" target="_blnk"></i>Opp. Lal Bagh, Tonk Road Beelwa, Jaipur</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section className="copy_write1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copy_write">
                <h5><i className="fa-regular fa-copyright"></i>Copyright 2024 by Techno It Solution</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}

export default Footer; 