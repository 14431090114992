// import React, { useEffect, useRef } from "react";
// import emailjs from '@emailjs/browser';
// import About from "./aboutUs";
// import $ from "jquery";
// import { Link } from "react-router-dom";

// const Home = () => {

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     if ($(".slide").length > 0) {
//       showSlides(slideIndex);
//       //   setInterval(function () {
//       //   plusSlides(1);
//       // }, 4000);
//     }
//     return () => {
//       if ($('.owl-carousel').data('owl.carousel')) {
//         $('.owl-carousel').trigger('destroy.owl.carousel');
//       }
//     };

//   }, []);

//   const form = useRef();

//   const sendEmail = (e) => {
//     const YOUR_SERVICE_ID = "";
//     const template_id = "";
//     const public_key = "";
//     e.preventDefault();

//     emailjs
//       .sendForm(YOUR_SERVICE_ID, template_id, form.current, {
//         publicKey: public_key,
//       })
//       .then(
//         () => {
//           console.log('SUCCESS!');
//           window.alert("mail send successful")
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         },
//       );
//   };

//   let slideIndex = 1;

//   function plusSlides(n) {
//     showSlides(slideIndex += n);
//   }

//   function currentSlide(n) {
//     showSlides(slideIndex = n);
//   }

//   function showSlides(n) {
//     let i;
//     let slides = document.getElementsByClassName("slide");
//     let dots = document.getElementsByClassName("dot");
//     if (n > slides.length) { slideIndex = 1 }
//     if (n < 1) { slideIndex = slides.length }
//     for (i = 0; i < slides.length; i++) {
//       slides[i].style.opacity = "0";
//     }
//     for (i = 0; i < dots.length; i++) {
//       dots[i].className = dots[i].className.replace(" active", "");
//     }
//     slides[slideIndex - 1].style.opacity = "1";
//     dots[slideIndex - 1].className += " active";
//   }

//   function nextSlide() {
//     plusSlides(1);
//   }

//   function prevSlide() {
//     plusSlides(-1);
//   }


//   return (
//     <>
//       <section className="position-relative">
//         <div className="slider">
//           <div className="slide banner-carousel .active .slide-item .image-layer ">
//             <div className="content_alo">
//               <div className="row">
//                 <h2> IF YOU WANT TO MAKE ANY WEBSITE </h2>
//                 <p>We are here to help you grow your business and create WEBSITE for your business.</p>
//                 <a href="about.html"> <button className="ad_btn">Learn More</button></a>

//               </div>
//             </div>

//             <img className="zoom-in-zoom-out" src="images/banner_orf.png" alt="Image 1" />

//           </div>
//           <div className="slide">
//             <div className="content_alo">
//               <h2> IF YOU WANT TO MAKE UI/UX FOR APP</h2>
//               <p>We are here to help you grow your business and create App for your business.</p>
//               <a href="about.html"> <button className="ad_btn">Learn More</button></a>
//             </div>
//             <img className="zoom-in-zoom-out" src="images/web_ani3.png" alt="Image 2" />
//           </div>
//           <div className="slide">
//             <div className="content_alo">
//               <h2> IF YOU WANT TO MAKE ANY GRAPHIC DESIGN</h2>
//               <p>We are here to help you grow your business and create any Graphic Post for your business.</p>
//               <a href="about.html"> <button className="ad_btn">Learn More</button></a>
//             </div>
//             <img className="zoom-in-zoom-out" src="images/web_ani2.png" alt="Image 3" />
//           </div>

//           <a className="prev" onClick={prevSlide}><i className="fa-solid fa-chevron-left"></i></a>
//           <a className="next" onClick={nextSlide}><i className="fa-solid fa-chevron-right"></i></a>
//           <div className="dots">
//             <span className="dot" onClick="currentSlide(1)"></span>
//             <span className="dot" onClick="currentSlide(2)"></span>
//             <span className="dot" onClick="currentSlide(3)"></span>

//           </div>

//         </div>


import React, { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import $ from "jquery";
import { Link } from "react-router-dom";
import About from "./aboutUs";


const Home = () => {
  const form = useRef();
  let slideIndex = 1;
  let slideInterval = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    if ($(".slide").length > 0) {
      showSlides(slideIndex);
      startSlideShow();
    }
    
    return () => {
      clearInterval(slideInterval.current);
      if ($('.owl-carousel').data('owl.carousel')) {
        $('.owl-carousel').trigger('destroy.owl.carousel');
      }
    };
  }, []);

  const sendEmail = (e) => {
    const YOUR_SERVICE_ID = "";
    const template_id = "";
    const public_key = "";
    e.preventDefault();

    emailjs
      .sendForm(YOUR_SERVICE_ID, template_id, form.current, {
        publicKey: public_key,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          window.alert("mail send successful");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  function startSlideShow() {
    slideInterval.current = setInterval(() => {
      plusSlides(1);
    }, 4000); // Change slide every 4 seconds
  }

  function plusSlides(n) {
    showSlides(slideIndex += n);
  }

  function currentSlide(n) {
    showSlides(slideIndex = n);
  }

  function showSlides(n) {
    let i;
    let slides = document.getElementsByClassName("slide");
    let dots = document.getElementsByClassName("dot");
    if (n > slides.length) { slideIndex = 1 }
    if (n < 1) { slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.opacity = "0";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.opacity = "1";
    dots[slideIndex - 1].className += " active";
  }

  function nextSlide() {
    plusSlides(1);
  }

  function prevSlide() {
    plusSlides(-1);
  }

  return (
    <>
      <section className="position-relative">
        <div className="slider">
          <div className="slide banner-carousel active slide-item image-layer">
            <div className="content_alo">
              <div className="row">
                <h2> IF YOU WANT TO MAKE ANY WEBSITE </h2>
                <p>We are here to help you grow your business and create WEBSITE for your business.</p>
                <Link to="/contact"> <button className="ad_btn">Learn</button></Link>
              </div>
            </div>
            <img className="zoom-in-zoom-out" src="images/banner_orf.png" alt="Image 1" />
          </div>
          </div>
          <div className="slide">
            <div className="content_alo">
              <h2> IF YOU WANT TO MAKE UI/UX FOR APP</h2>
              <p>We are here to help you grow your business and create App for your business.</p>
              <Link to="/contact"> <button className="ad_btn">Learn</button></Link>
            </div>
            <img className="zoom-in-zoom-out" src="images/web_ani3.png" alt="Image 2" />
          </div>
          <div className="slide">
            <div className="content_alo">
              <h2> IF YOU WANT TO MAKE ANY GRAPHIC DESIGN</h2>
              <p>We are here to help you grow your business and create any Graphic Post for your business.</p>
              <Link to="/contact"> <button className="ad_btn">Learn</button></Link>
            </div>
            <img className="zoom-in-zoom-out" src="images/web_ani2.png" alt="Image 3" />
          </div>
          <a className="prev" onClick={prevSlide}><i className="fa-solid fa-chevron-left"></i></a>
          <a className="next" onClick={nextSlide}><i className="fa-solid fa-chevron-right"></i></a>
          <div className="dots">
            <span className="dot" onClick={() => currentSlide(1)}></span>
            <span className="dot" onClick={() => currentSlide(2)}></span>
            <span className="dot" onClick={() => currentSlide(3)}></span>
            <span className="dot" onClick={() => currentSlide(4)}></span>
          </div>
  
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="apointment">
                  <h2>Book Appointment</h2>
                  <p className="all_perag">Book your appointment with Techno IT Solution today for expert tech assistance and
                    innovative solutions. Contact us now!</p>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="appoit_inp">
                          <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="appoit_inp">
                          <input type="email" className="form-control" id="floatingInputGroup1" placeholder="Your E-mail" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="appoit_inp">
                          <select className="form-select" aria-label="Default select example">
                            <option defaultValue>Services</option>
                            <option value="1">Web Development</option>
                            <option value="2">Web Designing</option>
                            <option value="3">App Development</option>
                            <option value="3">Graphic Designing</option>
                            <option value="3">Digital Marketing</option>
                            <option value="3">Education Management</option>
                            <option value="3">Bulk & Voice SMS</option>
                            <option value="3">Content Marketing</option>
                            <option value="3">Lead Generation</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6 text-end">
                        <div className="appot_btn">
                          <button className="ad_btn">Get Appointment</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="prov_serv">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="it_serv text-center">
                <h2 className="all_head">
                  World best IT-Services
                  we provide</h2>
                <p className="all_perag">Experience world-class IT services with us. From software development to cybersecurity,
                  we deliver innovative solutions tailored to your needs. Contact us for unparalleled expertise and support.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="card_prov ">

                <i className="fa-solid fa-laptop"></i>
                <h2>
                  Web Designing</h2>
                <p className="all_perag">Elevate your online presence with our creative web design solutions, blending
                  aesthetics and functionality to captivate your audience and drive results.</p>
              </div>
            </div>


            <div className="col-md-4">
              <div className="card_prov ">

                <i className="fa-solid fa-laptop-code"></i>
                <h2>

                  Web Development</h2>
                <p className="all_perag">Transform your ideas into reality with our expert web development services. We craft
                  responsive, user-friendly websites tailored to your unique requirements and goals.</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card_prov ">

                <i className="fa-solid fa-mobile-screen-button"></i>
                <h2>

                  App Development</h2>
                <p className="all_perag">Empower your business with our custom app development solutions. From concept to
                  launch, we create intuitive mobile applications that engage users and drive success.</p>
              </div>
            </div>
            <div className="allcours text-center">
              <Link to="/service"> <button className="ad_btn">All Services</button> </Link>
            </div>
          </div>
        </div>
      </section>
      <About />
    </>
  );
}

export default Home;




