import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PortFolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section class="aboutmain">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="about_bnre text-center">
                <h2>Portfolio</h2>
                <p><Link href="/">Home</Link> / <Link href="/portfolio">Portfolio</Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="portfolio-section all_padding" id="portfolio">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h2 class="all_head">Project Gallery</h2>

            </div>
          </div>
          <div class="portfolio-menu mt-2 mb-4">
            <nav class="controls">
              <button type="button" class="control outline" data-filter=".web">Graphic Design</button>
            </nav>
          </div>
          <ul class="row portfolio-item">
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-19.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">TECHNO IT SOLUTION</p>
                  <a href="images/design-19.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-18.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">ACOREBIT IT SOLUTION</p>
                  <a href="images/design-18.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>

            <li class="mix dev col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-16.jpg" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">MARUTI REAL ESTATE</p>
                  <a href="images/design-16.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>

            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-6.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SHRI NARAYAN GROUP</p>
                  <a href="images/design-6.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          
            <li class="mix web col-xl-3 col-md-4 col-12 col-sm-6 pd ">
              <img
                src="images/design-8.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SHRI NARAYAN GROUP</p>
                  <a href="images/design-8.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>

            
            <li class="mix dev col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-10.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">ARAVALI CAFE</p>
                  <a href="images/design-10.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix web col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-11.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SRI JI AUTOMOBILE</p>
                  <a href="images/design-11.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix dev col-xl-3 col-md-4 col-12 col-sm-6 pd ">
              <img
                src="images/design-7.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SHRI NARAYAN GROUP</p>
                  <a href="images/design-7.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix web col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-5.jpg" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">MARUTI REAL ESTATE</p>
                  <a href="images/design-5.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-12.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">MARKETING</p>
                  <a href="images/design-12.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix web col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-15.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">THE PUBLIC MALL</p>
                  <a href="images/design-15.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd ">
              <img
                src="images/design-9.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SHRI NARAYAN GROUP</p>
                  <a href="images/design-9.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/lecno_logo.png" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">TECHNO IT SOLUTION</p>
                  <a href="images/lecno_logo.png" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-17.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">HOTEL GIRIRAJ PALACE</p>
                  <a href="images/design-17.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
           
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-20.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">Vyom Reserach & Technology</p>
                  <a href="images/design-20.jpg" title="View Project" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="portfolio-section all_padding" id="portfolio">
        <div class="container-fluid">
          <div class="portfolio-menu mt-2 mb-4">
            <nav class="controls">
              <button type="button" class="control outline" data-filter=".web">WEB Development</button>
            </nav>
          </div>
          <ul class="row portfolio-item">
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/web-techno.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">Techno IT Solution</p>
                  <a href="https://technoitsolution.in/" title="Visit Site" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/web-shreejiautomobile.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">Shri ji AutoMobile</p>
                  <a href="https://www.shreejiautomobile.in/" title="Visit Site" target="_blank">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/web-shriNarayanGroup.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">Shree Narayan Group</p>
                  <a href="https://www.shreenarayangroups.com/" title="Visit Site" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix dev col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/web-shreedadudayalbuilders.jpg" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">Shree Dadu Dayal Builders</p>
                  <a href="https://www.shreedadudayalbuilders.in/" title="Visit Site" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li class="mix wp col-xl-3 col-md-4 col-12 col-sm-6 pd">
              <img
                src="images/design-6.jpg" alt="Image description" />
              <div class="portfolio-overlay">
                <div class="overlay-content">
                  <p class="category">SHRI NARAYAN GROUP</p>
                  <a href="images/design-6.jpg" title="Visit Site" target="_self">
                    <div class="magnify-icon">
                      <p><span><i class="fa fa-link" aria-hidden="true"></i></span></p>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="slid services-section">
        <div class=" headline-area z-1">
          <div class="container-fluid">
            <div class="headline-text marquee"><span>Best Web <span>Design</span> Company In Jaipur</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PortFolio;