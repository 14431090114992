// import React,{useEffect,useRef} from "react";
// import { Link } from "react-router-dom";
// import emailjs from '@emailjs/browser';

// const Contact = () => {

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);
    
//     const form = useRef();
    
//     const sendEmail = (e) => {
//         const YOUR_SERVICE_ID = "QPLrOh859PLKJkXwWHgZc";
//         const template_id = "template_sqst924";
//         const public_key = "lLNRx7cYTPLfkBY1F";
//         e.preventDefault();
    
//         emailjs
//             .sendForm("QPLrOh859PLKJkXwWHgZc", "template_sqst924", form.current, {
//                 publicKey: "lLNRx7cYTPLfkBY1F",
//             })
//             .then(
//                 () => {
//                     console.log('SUCCESS!');
//                     window.alert("mail send successful")
//                 },
//                 (error) => {
//                     console.log('FAILED...', error.text);
//                 },
//             );
//     };

//     return (
//         <>
//             <section className="aboutmain">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="about_bnre text-center">
//                                 <h2>Contact Us</h2>
//                                 <p><Link href="/">Home</Link> / <Link href="/contact">Contact Us</Link></p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="all_padding ">
//                 <div className="container">
//                     <div className="all_cont">
//                         <div className="row">
//                             <div className="col-md-12 text-center">
//                                 <div className="get_in dhery mb-5">
//                                     <h2 className="all_head">Get in touch</h2>
//                                     <p className="all_perag">Unlock the potential of your career with Tecno IT Solution's digital marketing courses. Contact us today for personalized guidance tailored to your career goals.</p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-md-6">
//                                 <div className="informations">
//                                     <div className="contact_sec">
//                                         <a href="tel:+91 8947943806"> <i className="fa-solid fa-phone"></i>
//                                             <br />
//                                         </a><a href="tel:+91 9571586263">+91 8947943806</a>
//                                     </div>
//                                     <div className="contact_sec">
//                                         <a href="mailto: dheeraydigitalacademy@gmail.com">
//                                             <i className="fa-solid fa-envelope"></i>
//                                             <br />
//                                         </a><a href="mailto: technoitsolution63@gmail.com"> technoitsolution63@gmail.com</a>
//                                     </div>
//                                     <div className="contact_sec">
//                                         <i className="fa-solid fa-location-dot"></i>
//                                         <br />
//                                         <a href="#">Opp. Lal Bagh, Tonk Road Beelwa, Jaipur</a>
//                                     </div>
//                                 </div>
//                             </div>
                            

//                             <div className="col-md-6">
//                                 <div className="contactfrm" bis_skin_checked="1">
//                               <form ref={form} onSubmit={sendEmail}>
//                                     <div className="form-inner" bis_skin_checked="1">
//                                         <div className="form-controls row" bis_skin_checked="1">
//                                             <div className="col-md-12" bis_skin_checked="1">
//                                                 <div className="appoit_inp">
//                                                     <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Your Name" />
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-12" bis_skin_checked="1">
//                                                 <div className="appoit_inp">
//                                                     <input type="email" className="form-control" id="floatingInputGroup1" placeholder="Your E-mail" />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="row" bis_skin_checked="1">
//                                             <div className="col-md-12" bis_skin_checked="1">
//                                                 <div className="appoit_inp">
//                                                     <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Your Mobile No." />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="row" bis_skin_checked="1">
//                                             <div className="col-md-12" bis_skin_checked="1">
//                                                 <div className="appoit_inp">
//                                                     <select className="form-select" aria-label="Default select example">
//                                                         <option selected>Services</option>
//                                                         <option value="1">Web Development</option>
//                                                         <option value="2">Web Designing</option>
//                                                         <option value="3">App Development</option>
//                                                         <option value="3">Graphic Designing</option>
//                                                         <option value="3">Digital Marketing</option>
//                                                         <option value="3">Education Management</option>
//                                                         <option value="3">Bulk & Voice SMS</option>
//                                                         <option value="3">Content Marketing</option>
//                                                         <option value="3">Lead Generation</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12" bis_skin_checked="1">
//                                             <div className="form-group_2" bis_skin_checked="1">
//                                                 <p>
//                                                     <span className="wpcf7-form-control-wrap" data-name="textarea-234">
//                                                         <textarea cols="20" rows="7"
//                                                             className="wpcf7-form-control wpcf7-textarea form-control"
//                                                             aria-invalid="false" placeholder="Your Message" name="Message"
//                                                             data-gramm="false" wt-ignore-input="true"></textarea>
//                                                     </span>
//                                                 </p>
//                                             </div>
//                                         </div>

//                                     </div>
//                               </form>

//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-md-12 text-center mt-5" bis_skin_checked="1">
//                                 <div className="form-group" bis_skin_checked="1">
//                                     <div className="buttons text-center" bis_skin_checked="1">
//                                         <button type="button" className="btn btn-primary ad_btn ">
//                                             Submit
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default Contact;



// Contact.js
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const form = useRef();
    
    const sendEmail = (e) => {
        const YOUR_SERVICE_ID = "service_sdwfgcn";
        const template_id = "template_sqst924";
        const public_key = "lLNRx7cYTPLfkBY1F";
        e.preventDefault();
    
        emailjs
            .sendForm(YOUR_SERVICE_ID, template_id, form.current, public_key)
            .then(
                () => {
                    console.log('SUCCESS!');
                    window.alert("mail sent successfully");
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <section className="aboutmain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <img src="images/img-1.jpg"/> */}
                            <div className="about_bnre text-center">
                                <h2>Contact Us</h2>
                                <p><Link to="/">Home</Link> / <Link to="/contact">Contact Us</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="all_padding ">
                <div className="container">
                    <div className="all_cont">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="get_in dhery mb-5">
                                    <h2 className="all_head">Get in touch</h2>
                                    <p className="all_perag">Unlock the potential of your career with Tecno IT Solution's digital marketing courses. Contact us today for personalized guidance tailored to your career goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="informations">
                                    <div className="contact_sec">
                                        <a href="tel:+91 8947943806"> <i className="fa-solid fa-phone"></i>
                                            <br />
                                        </a><a href="tel:+91 9571586263">+91 8947943806</a>
                                    </div>
                                    <div className="contact_sec">
                                        <a href="mailto: dheeraydigitalacademy@gmail.com">
                                            <i className="fa-solid fa-envelope"></i>
                                            <br />
                                        </a><a href="mailto: technoitsolution63@gmail.com"> technoitsolution63@gmail.com</a>
                                    </div>
                                    <div className="contact_sec">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <br />
                                        <a href="#">Opp. Lal Bagh, Tonk Road Beelwa, Jaipur</a>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-md-6">
                                <div className="contactfrm" bis_skin_checked="1">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="form-inner" bis_skin_checked="1">
                                            <div className="form-controls row" bis_skin_checked="1">
                                                <div className="col-md-12" bis_skin_checked="1">
                                                    <div className="appoit_inp">
                                                        <input type="text" name="from_name" className="form-control" id="floatingInputGroup1" placeholder="Your Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12" bis_skin_checked="1">
                                                    <div className="appoit_inp">
                                                        <input type="email" name="from-email" className="form-control" id="floatingInputGroup1" placeholder="Your E-mail" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" bis_skin_checked="1">
                                                <div className="col-md-12" bis_skin_checked="1">
                                                    <div className="appoit_inp">
                                                        <input type="text" className="form-control" name="number" id="floatingInputGroup1" placeholder="Your Mobile No." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" bis_skin_checked="1">
                                                <div className="col-md-12" bis_skin_checked="1">
                                                    <div className="appoit_inp">
                                                        <select className="form-select" name="value" aria-label="Default select example">
                                                            <option selected>Services</option>
                                                            <option value="Web Development">Web Development</option>
                                                            <option value="Web Designing">Web Designing</option>
                                                            <option value="App Development">App Development</option>
                                                            <option value="Graphic Designing">Graphic Designing</option>
                                                            <option value="Digital Marketing">Digital Marketing</option>
                                                            <option value="Education Management">Education Management</option>
                                                            <option value="Bulk & Voice SMS">Bulk & Voice SMS</option>
                                                            <option value="Content Marketing">Content Marketing</option>
                                                            <option value="Lead Generation">Lead Generation</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" bis_skin_checked="1">
                                                <div className="form-group_2" bis_skin_checked="1">
                                                    <p>
                                                        <span className="wpcf7-form-control-wrap" data-name="textarea-234">
                                                            <textarea cols="20" rows="7"
                                                                className="wpcf7-form-control wpcf7-textarea form-control"
                                                                aria-invalid="false" placeholder="Your Message" name="message"
                                                                data-gramm="false" wt-ignore-input="true"></textarea>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="buttons text-center" bis_skin_checked="1">
                                            <button type="submit" className="btn btn-primary ad_btn">
                                                Submit
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
