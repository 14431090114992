import React,{useEffect} from "react";
import { Link } from "react-router-dom";

const Gallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <>
            <section className="aboutmain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_bnre text-center">
                                <h2>Gallery</h2>
                                <p><Link href="/">Home</Link> / <Link href="/gallery">Gallery</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/gallery1.jfif" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/gallery2.jfif" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/gallery3.jfif" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/gallery4.jfif" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-5.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-6.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-7.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-8.jpg" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-17.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-16.jpg" alt="" />
                            </div>
                        </div>
                        
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-12.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-13.jpg" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">                       
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-15.jpg" alt="" />
                            </div>
                        </div>                      
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-14.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-19.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-11.jpg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-10.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-18.jpg" alt="" />
                            </div>
                        </div>

                        {/* <div className="col-md-3">
                            <div className="gallery">
                                <img src="images/design-16.jpg" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </>
    );
}

export default Gallery;