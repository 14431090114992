import React ,{useEffect} from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

const Service = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // $('.our-products').owlCarousel({
    //     loop:true,
    //     nav:false,
    //     center: true,
    //     margin:10,
    //     responsiveClass:true,
    //     responsive:{
    //         0:{
    //             items:1
    //         },
    //         600:{
    //             items:2
    //         },
    //         1000:{
    //             items:3
    //         }
    //     },
    //     autoplay:true, // Set to true to enable autoplay
    //     autoplayTimeout:3000, // Set the autoplay timeout in milliseconds (3 seconds in this case)
    //     autoplayHoverPause:true // Set to true to pause autoplay on hover
    // });

    // $('.feature-carousel').owlCarousel({
    //     loop:true,
    //     nav:false,
    //     margin:10,
    //     dots:false,
    //     autoplay:true,
    //     autoplayTimeout:3000,
    //     autoplayHoverPause:true,
    //     responsiveClass:true,
    //     responsive:{
    //         0:{
    //             items:1
    //         },
    //         600:{
    //             items:2
    //         },
    //         1000:{
    //             items:4
    //         }
    //     }
    // });

    return (
        <>
            <section className="aboutmain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_bnre text-center">
                                <h2>Services</h2>
                                <p><Link href="/">Home</Link> / <Link href="service.html">Services</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="prov_serv servmain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="it_serv text-center">
                                <h2 className="all_head">
                                    World best IT-Services
                                    we provide</h2>
                                <p className="all_perag">Experience world-className IT services with us. From software development to cybersecurity,
                                    we deliver innovative solutions tailored to your needs. Contact us for unparalleled expertise and support.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-laptop"></i>
                                <h2>
                                    Web Designing</h2>
                                <p className="all_perag">Elevate your online presence with our creative web design solutions, blending
                                    aesthetics and functionality to captivate your audience and drive results.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-laptop-code"></i>
                                <h2>
                                    Web Development</h2>
                                <p className="all_perag">Transform your ideas into reality with our expert web development services. We craft
                                    responsive, user-friendly websites tailored to your unique requirements and goals.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-mobile-screen-button"></i>
                                <h2>
                                    App Development</h2>
                                <p className="all_perag">Empower your business with our custom app development solutions. From concept to
                                    launch, we create intuitive mobile applications that engage users and drive success.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-pen-nib"></i>
                                <h2>
                                    Graphic Designing</h2>
                                <p className="all_perag">Elevate your brand with our professional graphic design services. From logos to
                                    marketing materials, we craft visually stunning designs that captivate your audience and drive results.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-bullhorn"></i>
                                <h2>
                                    Digital Marketing</h2>
                                <p className="all_perag">Maximize your online presence with our comprehensive digital marketing services. From
                                    SEO to social media, we help you reach your target audience effectively.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                                <i className="fa-solid fa-book-open-reader"></i>
                                <h2>
                                    Education Management</h2>
                                <p className="all_perag">Optimize education management with our tailored solutions. From administration to
                                    student engagement, we provide tools to enhance learning outcomes and streamline operations.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card_prov ">

                                <i className="fa-solid fa-envelopes-bulk"></i>
                                <h2>
                                    Bulk & Voice SMS</h2>
                                <p className="all_perag">Reach your audience effectively with our bulk and voice SMS services. Engage customers
                                    through personalized messaging and enhance communication with your target market.</p>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="card_prov ">

                                <i className="fa-regular fa-file-lines"></i>
                                <h2>

                                    Content Marketing</h2>
                                <p className="all_perag">Boost your brand's visibility and engagement with our strategic content marketing
                                    solutions. From compelling articles to captivating videos, we drive results through quality content.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card_prov ">

                                <i className="fa-solid fa-laptop-file"></i>
                                <h2>

                                    Lead Generation</h2>
                                <p className="all_perag">Drive growth for your business with our lead generation services. We identify and
                                    connect you with potential customers, helping you expand your client base effectively.</p>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card_prov ">
                            <i className="fa-solid fa-laptop-file"></i>
                                <h2>
                                    SEO Services</h2>
                                <p className="all_perag">We understand that every business is unique. That's why we tailor our SEO strategies to align with your specific goals, target audience, and industry landscape. Our comprehensive approach to SEO encompasses:</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                            <i className="fa-solid fa-laptop"></i>

                                <h2>
                                    GMB Services</h2>
                                <p className="all_perag">In today's competitive digital landscape, having a strong local presence is essential for businesses of all sizes. We specialize in Google My Business (GMB) services that help businesses stand out in local search results, attract more customers, and drive growth.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card_prov ">
                            <i className="fa-solid fa-laptop-code"></i>

                                <h2>
                                    SMO Service</h2>
                                <p className="all_perag">In today's digital age, a robust social media presence is essential for businesses to connect with their audience, boost brand visibility, and foster engagement. At [Your Company Name], we excel in Social Media Optimization (SMO) services, empowering businesses to harness the potential of social platforms for their marketing goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="slid services-section">
                <div className=" headline-area z-1">
                    <div className="container-fluid">
                        <div className="headline-text marquee"><span>Best Web <span>Design</span> Agency In Jaipur</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="all_padding ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="popular_courses why_chose">
                                <h5>Question</h5>
                                <h2 className="all_head">Seamless & hassle-free IT Support</h2>
                                <p className="all_perag mb-0">Experience seamless and hassle-free IT support with Tecno IT Solution. Our expert
                                    team ensures smooth operations, timely assistance, and proactive solutions to keep your business running
                                    efficiently.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            What is the Cancelation Period?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">The cancellation period refers to the duration within which a
                                            contract or agreement can be terminated without incurring penalties or fees. It typically varies
                                            depending on the terms outlined in the contract.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            What does non-exclusive mean?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">Non-exclusive means not limited to one party. It allows multiple
                                            parties to participate or benefit. In business, it offers flexibility by permitting engagement with
                                            other parties simultaneously.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Where are your office located?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">
                                            Our office is located in Opp. Lal Bagh, Tonk Road Beelwa, Jaipur, providing convenient access for our
                                            clients. If you need further assistance, feel free to reach out to us for directions or additional
                                            information.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="all_padding review_test">
                <div className="container">
                    <div className="our-project-carousel-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="popular_courses sert text-center">
                                        <h5>Testimonials</h5>
                                        <h2 className="all_head hrt">Review From Our Members</h2>
                                        <p className="all_perag hrt">Choose the best digital marketing course for your career growth at Dheeray
                                            Digital
                                            Academy. Explore our comprehensive curriculum, expert faculty, and flexible learning options to find
                                            the
                                            perfect fit for your learning style and career goals.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-carousel our-products owl-theme">
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Kabir Singh</h4>
                                                    <p>Web Designer</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Techno IT Solution exceeded our expectations with their exceptional service and
                                            expertise. Their innovative solutions truly transformed our business. Highly recommended!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Kabir Singh</h4>
                                                    <p>Web Designer</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Techno IT Solution exceeded our expectations with their exceptional service and
                                            expertise. Their innovative solutions truly transformed our business. Highly recommended!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Kabir Singh</h4>
                                                    <p>Web Designer</p>

                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Techno IT Solution exceeded our expectations with their exceptional service and
                                            expertise. Their innovative solutions truly transformed our business. Highly recommended!</p>

                                    </div>
                                </div>

                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Kabir Singh</h4>
                                                    <p>Web Designer</p>

                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Techno IT Solution exceeded our expectations with their exceptional service and
                                            expertise. Their innovative solutions truly transformed our business. Highly recommended!</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Service;