import React from "react";
import { Link } from "react-router-dom";


const Header = ()=>{

    return(

        <>
         <div className="back_img">
                <header className="header">
                    <div className="container">
                        <div className="row-wrap align-items-baseline">
                            <div className="logo-wrap">
                                <Link className="logo" to="/"><img src="images/lecno_logo.png" alt="" /></Link>
                            </div>
                            <div className="nav-wrap">
                                <ul className="nav-list">
                                    <li><Link to="/" >Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/service">Services</Link></li>
                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>

                                    {/* <li><Link href="/contact">Contact</Link></li> */}
                                    {/* <Link href="/contact"></Link> */}
                                    <button className="ad_btn"><Link to="/contact">Appointment</Link></button>
                                </ul>
                            </div>
                            <div className="hamburger">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </div>
                        </div>
                    </div>
                </header >
            </div >        
        </>
    );
}

export default Header ;