import React ,{useState , useEffect} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/header';
import Home from './components/home'
import About from './components/aboutUs';
import Contact from './components/contact';
import Footer from './components/footer';
import Service from './components/service';
import Subscribe from './components/Subscribe';
import PortFolio from './components/portfolio';
import Gallery from './components/gallery';
import Loader from './components/loader';



function App() {  




  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className= "App">
    <BrowserRouter>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/about" element={<About />} />
         <Route path="/service" element={<Service />} />
          <Route path='/contact' element={<Contact />}/>
          <Route path='/portfolio' element={<PortFolio />}/>
          <Route path='/gallery' element={<Gallery />}/>
        </Routes>
        <Subscribe />
        <Footer/>
      </div>
    </BrowserRouter>
    </div>
  );
}

export default App;

