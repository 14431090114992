import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        if ($(".slide").length > 0) {
            showSlides(slideIndex);
        }
        $(document).ready(function () {
            if ($('.our-products').length > 0) {
                $('.our-products').owlCarousel({
                    loop: true,
                    nav: false,
                    center: true,
                    margin: 10,
                    responsiveClass: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    },
                    autoplay: true,
                    autoplayTimeout: 3000,
                    autoplayHoverPause: true
                });
            }

        });

    }, []);

    let slideIndex = 1;

    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("slide");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) { slideIndex = 1 }
        if (n < 1) { slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.opacity = "0";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[slideIndex - 1].style.opacity = "1";
        dots[slideIndex - 1].className += " active";
    }

    return (
        <>
            {/* about block start  */}
            <section className="aboutmain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_bnre text-center">
                                <h2>About Us</h2>
                                <p><Link href="/">Home</Link> / <Link href="/aboutUs">About Us</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="all_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="popular_courses why_chose">
                                <h5>About</h5>
                                <h2 className="all_head">Best IT-Solutions company In Jaipur</h2>
                                <p className="all_perag mb-4">Techno IT Solution stands as the premier IT solutions company in Jaipur, offering
                                    a comprehensive suite of services tailored to meet diverse business needs. With a focus on innovation and
                                    reliability, we excel in software development, cybersecurity, web and app development, and managed IT
                                    services. Our team of experts combines technical proficiency with creativity to deliver cutting-edge
                                    solutions that drive business growth. Whether you're a startup or an enterprise, we provide personalized
                                    services to help you stay ahead in today's competitive landscape. Partner with us for unparalleled
                                    expertise, exceptional customer service, and a commitment to your success.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about_in_im">
                                <img src="images/aboutim.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="padd_all counter-s">
                <div className="container">
                    <div className="wrapper">
                        <div className="counter col_fourth">
                            <h2 className="timer count-title count-number" data-to="350" data-speed="2000" >350</h2>
                            <p className="count-text ">Happy Clients</p>
                        </div>
                        <div className="counter col_fourth">
                            <h2 className="timer count-title count-number" data-to="130" data-speed="2000" >130</h2>
                            <p className="count-text ">Project Complate</p>
                        </div>
                        <div className="counter col_fourth">
                            <h2 className="timer count-title count-number" data-to="5" data-speed="2000" >5</h2>
                            <p className="count-text ">Years Of Experience</p>
                        </div>
                        <div className="counter col_fourth end">
                            <h2 className="timer count-title count-number" data-to="24" data-speed="2000" >24</h2>
                            <p className="count-text ">Expert Team Member</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Question block start */}
            <section className="all_padding ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="popular_courses why_chose">
                                <h5>Question</h5>
                                <h2 className="all_head">Seamless & hassle-free IT Support</h2>
                                <p className="all_perag mb-0">Experience seamless and hassle-free IT support with Tecno IT Solution. Our expert
                                    team ensures smooth operations, timely assistance, and proactive solutions to keep your business running
                                    efficiently.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            What is the Cancelation Period?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">The cancellation period refers to the duration within which a
                                            contract or agreement can be terminated without incurring penalties or fees. It typically varies
                                            depending on the terms outlined in the contract.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            What does non-exclusive mean?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">Non-exclusive means not limited to one party. It allows multiple
                                            parties to participate or benefit. In business, it offers flexibility by permitting engagement with
                                            other parties simultaneously.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Where are your office located?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                                        data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body all_perag">
                                            Our office is located in Opp. Lal Bagh, Tonk Road Beelwa, Jaipur, providing convenient access for our
                                            clients. If you need further assistance, feel free to reach out to us for directions or additional
                                            information.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Question block end */}

            {/* Best Web Design Agency In Jaipur slid  */}
            <section className="slid services-section">
                <div className=" headline-area z-1">
                    <div className="container-fluid">
                        <div className="headline-text marquee"><span>Best Web <span>Design</span> Agency In Jaipur</span>
                        </div>
                    </div>
                </div>
            </section>
            {/* end slid */}

            {/* our best experts block start */}
            <section className="all_padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="team">
                                <h2 className="all_head">Meet with our
                                    best experts</h2>
                                <p className="all_perag">Meet with our top experts to discuss your IT needs. Our seasoned professionals offer
                                    tailored solutions to drive your business forward. Schedule a consultation today and experience the
                                    difference firsthand.</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="main">
                                <div className="profile-card">
                                    <div className="img">
                                        <img src="images/team1.jpg" />
                                    </div>
                                    <div className="caption">
                                        <h3>Jitendra Sharma</h3>
                                        <p>Founder & CEO</p>
                                        <div className="social-links">
                                            <a href="https://www.facebook.com/profile.php?id=100026218294469&mibextid=LQQJ4d" target="blank"><i className="fab fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/jit_u555?igsh=eTA4NDl1dHdjdjMy" target="blank"><i className="fab fa-instagram"></i></a>
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-card">
                                    <div className="img">
                                        <img src="images/team-2.jpg" />
                                    </div>
                                    <div className="caption">
                                        <h3>Deepak Sharma</h3>
                                        <p>Marketing Head</p>
                                        <div className="social-links">
                                            <a href="https://www.facebook.com/profile.php?id=100048281617102&mibextid=LQQJ4d" target="blank"><i className="fab fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/deepaksharma2082001?igsh=MW5meHdqNXNybXJzMQ==" target="blank"><i className="fab fa-instagram"></i></a>
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our best experts block end */}

            {/* Testimonials Review Members block start */}
            <section className="all_padding review_test">
                <div className="container">
                    <div className="our-project-carousel-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="popular_courses sert text-center">
                                        <h5>Testimonials</h5>
                                        <h2 className="all_head hrt">Review From Our Members</h2>
                                        <p className="all_perag hrt">Choose the best digital marketing course for your career growth at Dheeray
                                            Digital
                                            Academy. Explore our comprehensive curriculum, expert faculty, and flexible learning options to find
                                            the
                                            perfect fit for your learning style and career goals.</p>
                                    </div>
                                </div>
                            </div>
                            <OwlCarousel className='owl-theme' loop autoplay margin={10} nav>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Jitendra Sharma</h4>
                                                    <p>CEO-Founder </p>

                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Jitendra Sharma heads Techno IT Solution, a leader in IT solutions. With a passion for technology and strong business acumen, he drives the company's success in software development, IT consulting, and cybersecurity, empowering businesses for the digital age !</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team-2.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Deepak Sharma</h4>
                                                    <p>Marketing Head</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Deepak Sharma spearheads marketing at Techno IT Solution, driving strategic campaigns to elevate our brand and showcase innovative IT solutions. With a robust digital marketing background and a focus on customer engagement, he delivers impactful strategies that propel business growth!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team-6.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Bharat Mourya</h4>
                                                    <p>Content writer & Video Editor </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Bharat Mouriya is the talented Content Writer & Video Editor at Techno IT Solution, dedicated to crafting compelling narratives and visually engaging content. With a passion for storytelling and a keen eye for detail, Bharat brings creativity and expertise to every project!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team-4.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Karan Saini </h4>
                                                    <p>Web Designer</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">
                                        Karan Saini, Web Designer at Techno IT Solution, crafts intuitive and visually appealing digital experiences. With a passion for user-centric design and expertise in web development technologies, Karan ensures each website surpasses client expectations.!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team-3.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Deepak Sharma</h4>
                                                    <p>Graphic Designer</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Deepak Sharma is the talented Graphic Designer at Techno IT Solution, bringing creativity and innovation to every project. With a keen eye for detail and a passion for visual storytelling, Deepak crafts compelling designs that elevate the brand identity and communication strategies of Techno IT Solution.!</p>
                                    </div>
                                </div>
                                <div className="carousel">
                                    <div className="project-image">
                                        <div className="row d-flex">
                                            <div className="col-md-2">
                                                <div className="review_img">
                                                    <img src="images/team-5.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="review_txt">
                                                    <h4>Animesh </h4>
                                                    <p>Social Media Manager</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="all_perag">Animesh Choudhary, Social Media Manager at Techno IT Solution, blends creativity with strategy to enhance our digital presence. With a passion for social media and expertise in engaging content, Animesh ensures Techno IT Solution maintains visibility and connectivity across platforms !</p>
                                    </div>
                                </div>
                          
                            </OwlCarousel>
                            {/* <div className="owl-carousel our-products owl-theme">
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* Testimonials Review Members block end */}


            {/* about block end */}
        </>

    );
}

export default About;