import React,{useRef} from "react";
import emailjs from '@emailjs/browser';

const Subscribe = () => {

    const form = useRef();
    
    const sendEmail = (e) => {
        const YOUR_SERVICE_ID = "";
        const template_id = "";
        const public_key = "";
        e.preventDefault();
    
        emailjs
            .sendForm(YOUR_SERVICE_ID, template_id, form.current, {
                publicKey: public_key,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    window.alert("mail send successful")
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <section className="all_padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="subsc">
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <h2 className="newslt">Subscribe Our Newsletter</h2>
                                </div>
                                <div className="col-md-5 text-end">
                                <form ref={form} onSubmit={sendEmail}>

                                    <div className="inpt">
                                        <input type="email" className="form-control" placeholder="Enter Your Email Address" aria-label="Username"
                                            aria-describedby="basic-addon1" />
                                        <a className="secbrib" href="#"><button className="ad_btn">Subscribe</button></a>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Subscribe;